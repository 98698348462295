<template>
  <!-- <NavBar></NavBar> -->
  <div class="client-banner">
    <!-- <v-app-bar></v-app-bar> -->
    <v-toolbar class="bg-transparent text-center">
      <v-toolbar-title>
        <h2 class="text-start">
          <NuxtLink class="text-white" to="/"> Lifeline </NuxtLink>
        </h2>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-list class="bg-transparent text-h6 d-none d-lg-flex d-md-flex">
        <v-list-item>
          <NuxtLink class="text-white" to="/">Home</NuxtLink>
        </v-list-item>
        <v-list-item
          ><NuxtLink class="text-white" to="/jobs">Jobs</NuxtLink></v-list-item
        >
        <v-list-item
          ><NuxtLink class="text-white" to="/about"
            >About</NuxtLink
          ></v-list-item
        >
        <v-list-item
          ><NuxtLink class="text-white" to="/faq">Faq</NuxtLink></v-list-item
        >
      </v-list>
      <v-spacer></v-spacer>
      <div>
        <v-btn
          icon="mdi-account-badge-outline"
          variant="text"
          color="white"
        ></v-btn>
        <v-btn
          @click="toggleTheme"
          icon="mdi-weather-night"
          variant="text"
          color="white"
        ></v-btn>
        <v-btn
          icon="mdi-view-grid-plus-outline"
          variant="text"
          color="white"
        ></v-btn>
      </div>
    </v-toolbar>

    <v-row class="pa-5 text-white d-lg-flex align-end d-none">
      <v-col class="text-lg-start mt-10 d-flex align-end" cols="12" lg="6">
        <div class="glass pa-5">
          <h2 class="text-h4 font-weight-bold">
            Lifeline Educational Solutions
          </h2>
          <h5 class="text-h6">Get detailed information on the job you seek.</h5>
        </div>
      </v-col>
      <v-col cols="12" lg="6"></v-col>
    </v-row>
  </div>

  <div>
    <slot />
  </div>

  <!-- <ContactUs></ContactUs> -->
  <Footer></Footer>
</template>

<script setup>
import { useTheme } from "vuetify";
const theme = useTheme();
function toggleTheme() {
  theme.global.name.value = theme.global.current.value.dark ? "light" : "dark";
}
</script>

<style scoped></style>
